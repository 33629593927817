import React, { useEffect, useState } from 'react'
import { TextField, FormControl, InputLabel, Select, MenuItem, FormGroup, FormLabel, Switch, Stack, Box, Button, Input, Paper, Grid, Typography } from "@mui/material";

import { useQuery, useGateways, useVehicles } from "contexts";
import { assign_gateway_to_vehicle_mutation } from "queries";

import { Loading } from "common";



function VehicleGatewayAssociationForm() {
  const {
      loading,
      error,
      refetchData
  } = useQuery(assign_gateway_to_vehicle_mutation, { enable: false });

  const { gateways: gateway_state } = useGateways();
  const { gateways } = gateway_state;
  const { vehicles: vehicle_state } = useVehicles();
  const { vehicles } = vehicle_state;

  const [active, setActive] = useState(true);
  const [gateway, setGateway] = useState(null);
  const [vehicle, setVehicle] = useState(null);
  
    

  async function on_submit() {
    if (active) {
      if (gateway === null || vehicle === null) {
        // TODO: Error
      }

      refetchData({ input: { gateway, vehicle }}, true);
    }
  }



  useEffect(() => {
    if (error !== null) {
      console.error(error);
      return;
    }

    if (!loading) {
      refetchData(null, false);
      setActive(false);
    }
  }, [loading, error, refetchData]);

  
    
  if (gateways === null || vehicles === null) {
    return <Loading />;
  }


  
  const submit_button = <Button type="submit" onClick={on_submit} disabled={!active} variant="contained" sx={{ width: "100%" }}>Assign</Button>;

  const gateway_id = (gateway !== null) ? gateway : "" ;
  const gateway_input = <FormControl >
    <InputLabel>Gateway</InputLabel>
    <Select value={gateway_id} label="Gateway" onChange={(e) => setGateway(e.target.value)}>
      {gateways.map((g) => <MenuItem value={g.id} key={g.id}>{g.identifier}</MenuItem>)}
    </Select>
  </FormControl>;
  
  const vehicle_id = (vehicle !== null) ? vehicle : "" ;
  const vehicle_input = <FormControl >
    <InputLabel>Vehicle</InputLabel>
    <Select value={vehicle_id} label="Vehicle" onChange={(e) => setVehicle(e.target.value)}>
      {vehicles.map((v) => <MenuItem value={v.id} key={v.id}>{v.identifier}</MenuItem>)}
    </Select>
  </FormControl>;

  return (
    <Box display="flex" width="100%" height="100vh">
      <Box display="flex" padding="30px" width="100%" flexDirection="column" overflow="scroll">
        <div style={{ margin: "2rem", height: "20vh"}}>
          <Box margin="5px">
            <Paper sx={{ padding: 2, margin: 'auto' }}>
              <Grid container spacing={2} sx={{ display: 'flex', justifyContent: "space-between", width: "100%" }}>
                <Grid item xs={2}>
                  <Grid item xs={12}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: "26px" }}>New Vehicle</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ textAlign: 'center', padding: "25px" }}>
                      {gateway_input}
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ textAlign: 'center', padding: "25px" }}>
                      {vehicle_input}
                    </Box>
                  </Grid>
                  <Grid item xs={10}>
                    <Box sx={{ textAlign: "right" }}>
                      {submit_button}
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Box>
        </div>
      </Box>
    </Box>
  )
}

export { VehicleGatewayAssociationForm };
