import PeopleLogo1 from "assets/1people.png";
import PeopleLogo2 from "assets/2people.png";
import PeopleLogo3 from "assets/3people.png";
// import ArrowIcon from "assets/arrow.png";

const person_1 = new Image();
const person_2 = new Image();
const person_3 = new Image();
// const arrow = new Image();

person_1.src = PeopleLogo1;
person_2.src = PeopleLogo2;
person_3.src = PeopleLogo3;
// arrow.src = ArrowIcon;

const bus_radius = 15;

const bus_duress = {
  radius: bus_radius,
  width: bus_radius * 2,
  height: bus_radius * 2,
  data: new Uint8Array(bus_radius * 2 * bus_radius * 2 * 4),

  // called once before every frame where the icon will be used
  render: function () {
    const duration = 1000;
    const t = (performance.now() % duration) / duration;

    const inner_radius = this.radius * 0.3;
    const outer_radius = this.radius * 0.7 * t + inner_radius;
    const context = this.context;

    // draw outer circle
    context.clearRect(0, 0, this.width, this.height);
    context.beginPath();
    context.arc(this.width / 2, this.height / 2, outer_radius, 0, Math.PI * 2);
    context.fillStyle = "rgba(255, 200, 200," + (1 - t) + ")";
    context.fill();

    // draw inner circle
    context.beginPath();
    context.arc(this.width / 2, this.height / 2, inner_radius, 0, Math.PI * 2);
    context.fillStyle = "rgba(255, 100, 100, 1)";
    context.strokeStyle = "#ffffff";
    context.lineWidth = 2 + 4 * (1 - t);
    context.fill();
    context.stroke();

    // update this image's data with data from the canvas
    this.data = context.getImageData(0, 0, this.width, this.height).data;

    // continuously repaint the map, resulting in the smooth animation of the dot
    this.map.triggerRepaint();

    // return `true` to let the map know that the image was updated
    return true;
  },
};

const bus = {
  radius: bus_radius,
  width: bus_radius * 2,
  height: bus_radius * 2,
  data: new Uint8Array(bus_radius * 2 * bus_radius * 2 * 4),

  // get rendering context for the map canvas when layer is added to the map
  onAdd: function () {
    const canvas = document.createElement("canvas");
    canvas.width = this.width;
    canvas.height = this.height;
    this.context = canvas.getContext("2d");
  },

  // called once before every frame where the icon will be used
  render: function () {
    const inner_radius = this.radius * 0.7;
    const outer_radius = this.radius * 0.3 + inner_radius;
    const context = this.context;

    let lingrad = context.createLinearGradient(0, 0, 0, 150);
    lingrad.addColorStop(0, "#00ABEB");
    lingrad.addColorStop(0.5, "#fff");
    lingrad.addColorStop(0.5, "#26C000");
    lingrad.addColorStop(1, "#fff");

    // draw outer circle
    context.clearRect(0, 0, this.width, this.height);
    context.beginPath();
    context.arc(this.width / 2, this.height / 2, outer_radius, 0, Math.PI * 2);
    context.fillStyle = lingrad;
    context.fill();

    // draw inner circle
    context.beginPath();
    context.arc(this.width / 2, this.height / 2, inner_radius, 0, Math.PI * 2);
    context.fillStyle = "rgba(0, 0, 255)";
    context.strokeStyle = "#ffffff";
    context.lineWidth = 1;
    context.fill();
    context.stroke();

    // update this image's data with data from the canvas
    this.data = context.getImageData(0, 0, this.width, this.height).data;

    // return `true` to let the map know that the image was updated
    return true;
  },
};

export { person_1, PeopleLogo1, PeopleLogo2, PeopleLogo3, person_2, person_3, bus_duress, bus };
