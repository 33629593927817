import { Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { Chip } from "@mui/material";
import BoltIcon from '@mui/icons-material/Bolt';
import PowerIcon from '@mui/icons-material/Power';
import { Icon } from "@mui/material";

import Box from '@mui/material/Box';
import {
  DataGrid
} from '@mui/x-data-grid';
import clsx from 'clsx';
import * as React from 'react';
import { last, set } from "lodash";
import { formatDistanceToNow } from 'date-fns';

const ac = new AbortController();
// const endpoint = 'https://gemini.orionair.com.au:8443/graphql';

function ChargingVehicleList({ vehicles, charge_points }) {
  const navigate = useNavigate();

  vehicles?.map((v) => {
    var cpcs = [];
    charge_points.forEach(element => {
      cpcs.push(...element.chargePointConnectors);
    });
    let cpc = cpcs?.find(e => e.connectedVehicle == v.id);
    if (cpc === undefined) {
      cpc = null;
    }
    v.chargePointConnector = cpc;
  });

  // import {formatDistanceToNow} from "date-fns"
  function formatDistanceDay(date) {
      const oneDay = 1000 * 3600 * 24;
      const distance = Date.now() - date.getTime();
      if (distance < oneDay && distance > 0) {
          return "today";
      }
      return formatDistanceToNow(date, { addSuffix: true });
  }

  const initialRows = vehicles.map((vehicle, index) => {
    const schedules = [];
    vehicle['chargingSchedules'].forEach(cs => {
      schedules.push({ value: cs.id, label: cs.name });
    });

    console.log("Setting cpName to ", vehicle.chargePoint?.name);

    return {
      id: vehicle.id,
      name: vehicle.name,
      status: vehicle.status,
      chargePointConnector: vehicle.chargePointConnector,
      currentChargingSchedule: vehicle.currentChargingSchedule,
      depotState: vehicle.depotState === "within_depot" ? "Depot" : "Running",
      displayName: vehicle.displayName,
      stateOfCharge: vehicle.stateOfCharge,
      connected: vehicle.chargePointConnector?.state,
      current_draw: vehicle.current_draw,
      current_limit: vehicle.current_limit,
      x: vehicle.x,
      y: vehicle.y,
      schedules: schedules,
      // lastBatteryCycle: vehicle.lastBatteryCycle?.startedAt ? 
      //                   vehicle.lastBatteryCycle.lowestStateOfCharge + " - " + vehicle.lastBatteryCycle.highestStateOfCharge + "%  - " +
      //                   formatDistanceDay(new Date(vehicle.lastBatteryCycle.finishedAt), { addSuffix: true }):
      //                   null,
      lastChargeAgo: vehicle.lastBatteryCycle?.startedAt ?
                        formatDistanceDay(new Date(vehicle.lastBatteryCycle.finishedAt), { addSuffix: true }) :
                        null,
      lastChargePercs: vehicle.lastBatteryCycle?.startedAt ?
                        vehicle.lastBatteryCycle.lowestStateOfCharge + " - " + vehicle.lastBatteryCycle.highestStateOfCharge + "%" :
                        null, 
      cpName: vehicle.chargePoint?.name
    };
  });

  const columns = [
    { field: 'displayName', headerName: 'Name', width: 100, editable: false },
    { field: 'depotState', headerName: 'Location', width: 100, editable: false },
    {
      field: 'stateOfCharge', headerName: 'SoC', width: 50, editable: false,
      cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }

        return clsx('super-app', {
          green: params.value >= 90,
          red: params.value < 90,
        });
      },
    },
    { field: 'lastChargeAgo', headerName: 'Charged', width: 100, editable: false },
    { field: 'lastChargePercs', headerName: 'Delta', width: 100, editable: false },
    {
      field: 'connected',
      headerName: 'Connected',
      width: 150,
      renderCell: (params) => {
        let icon;
        switch (params.value) {
          case "connected":
            icon = (
              <Tooltip title="Connected">
              <PowerIcon />
              </Tooltip>
            );
            break;
          case "charging":
                icon = (
                <Tooltip title="Charging">
                  <BoltIcon className="animate-bolt" />
                </Tooltip>
                );
                break;
          case "manual_charging":
            icon = (
              <Tooltip title="Manually Charging">
              <BoltIcon className="animate-bolt" />M
              </Tooltip>
            );
            break;
          case "manual_connected":
            icon = (
              <Tooltip title="Manually Connected">
              <PowerIcon />M
              </Tooltip>
            );
            break;
          default:
            icon = <Icon />;
            break;
        }
        return <span>{icon}</span>;
      },
      editable: false,
    },
    {
      field: 'cpName',
      headerName: 'Charge Point',
      width: 150,
      editable: false,
    },
    {
      field: 'schedules',
      headerName: 'Assigned Schedules',
      width: 150,
      editable: true,
      type: "singleSelect",
      minWidth: 120,
      flex: 1,
      renderCell: (params) => (
        <>
          {params.value.map((element) => (
            <Chip
              key={element.value}
              label={element.label}
              variant="outlined"
            />
          ))}
        </>
      ),

    },
  ];

  // Map the vehicles data to rows for the DataGrid
  return (
    <Box style={{ height: "100%", width: '100%' }}>
      <DataGrid
        rowHeight={34}
        rows={initialRows}
        columns={columns}
        // pageSize={10}
        rowsPerPageOptions={[]}
        disableSelectionOnClick
        editMode="row"
        // rowModesModel={rowModesModel}
        // onRowModesModelChange={handleRowModesModelChange}
        // slotProps={{
        //   toolbar: { setRows, setRowModesModel },
        // }}
        onRowClick={(params, event) => {
          console.log("onRowClick", params, event);
          navigate(`/vehicle/charging/${params.id}`);
        } }
        sx={{
          '& .super-app-theme--cell': {
            backgroundColor: 'rgba(224, 183, 60, 0.55)',
            color: '#1a3e72',
            fontWeight: '600',
          },
          '& .super-app.green': {
            backgroundColor: 'rgba(157, 255, 118, 0.49)',
            color: '#1a3e72',
            fontWeight: '600',
          },
          '& .super-app.red': {
            backgroundColor: '#d47483',
            color: '#1a3e72',
            fontWeight: '600',
          },
        }} />
    </Box>
  );
}


export { ChargingVehicleList };




// https://github.com/mui/mui-x/issues/4410