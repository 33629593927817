import React, { createContext, useContext, useState, useEffect, useReducer } from "react";
import auth_config from "../auth_config.json";
import { useAuth0 } from '@auth0/auth0-react';
import { user_reducer } from "reducers";
import { useQuery } from "contexts"; 
import { register_user_mutation } from "queries";



const UserContext = createContext();

function UserContextProvider({ children }) {
  const issuer = `https://${auth_config.domain}/`;

  const state = {
    issuer
  };

  const [user, dispatch] = useReducer(user_reducer, state);

  return (
    <UserContext.Provider value={{ user, dispatch }}>
      {children}
    </UserContext.Provider>
  );
};



function useUser() {
  const context = useContext(UserContext);
  const { user, dispatch } = context;
  const { user: auth0_user } = useAuth0();
  const [ registered, set_registered ] = useState(false);
  const [ registering, set_registering ] = useState(false);
  const { data, loading, error, refetchData } = useQuery(register_user_mutation, { enable: false });

  function register_user() {
    if (!registered && !registering) {
      set_registering(true);
      if (user != null) {
        const { email, issuer, identifier } = user;
        if (email != null && issuer != null && identifier != null) {
          refetchData({ email, issuer, identifier }, true);
        }
      }
    }
  }

  useEffect(() => {
    if (auth0_user != null) {
      dispatch({ type: "SET_AUTH0_USER", payload: auth0_user, data: data })
    }
  }, [dispatch, auth0_user])

  useEffect(() => {
    if (error != null) {
      console.error(error);
      return;
    }

    if (!loading) {
      const { registerUser } = data;
      if (registerUser.successful) {
        // Successfully registered
        console.log("Successfully registered user!", data);
        set_registered(true);
      } else if (registerUser.errorCode === 409) {
        // Already registered
        console.log("User already registered!", data);
        set_registered(true);
      } else {
        console.error("Failed to register user: " + JSON.stringify(registerUser));
      }

      set_registering(false);
    }
  }, [registered, set_registered, set_registering, data, loading, error])

  return { registered, context, register_user };
}

export { useUser, UserContextProvider, UserContext };
