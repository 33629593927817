import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { styled } from '@mui/material/styles';
import { Button, Box } from "@mui/material";
import { useMap } from 'react-map-gl';

import { useUser, useQuery, useMapContext } from 'contexts'
import { TIME_AROUND_POS, FILTER_PANEL_WIDTH } from 'classes'
import { alert_query, position_between } from 'queries'

import { VehiclePin, AlertPin } from 'components'
import { Map, AlertCard, VehicleCard, RouteCard, SidePanel } from 'common'
import GemDrawerMenu from "components/common/DrawerMenu";

import { RouteLayer, StopLayer, SpeedHeadingLayer } from 'components/map-layers'

const get_location = (alert) => {
  if (alert?.position?.lat && alert?.position?.lon)
    return {
      lat: alert.position.lat,
      lon: alert.position.lon,
      zoom: 14,
    }

  if (alert?.vehicle?.lastPosition?.lat && alert?.vehicle?.lastPosition?.lon)
    return {
      lat: alert.vehicle.lastPosition.lat,
      lon: alert.vehicle.lastPosition.lon,
      zoom: 14,
    }

  return {
    lat: -25.27743988,
    lon: 133.7751312,
    zoom: 4,
  }
}

const MoveableMap = styled(Map, { shouldForwardProp: (prop) => prop !== 'open'})(({ theme, open }) => ({
  zIndex: 0,
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  ...(open && {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: FILTER_PANEL_WIDTH,
  })
}))

const OpenButton = styled(Button, { shouldForwardProp: (prop) => prop !== "hidden" })(({ hidden }) => ({
  zIndex: 1,
  ...(hidden && { display: "none" })
}))

function AlertView() {
  const { context: user_context, register_user } = useUser();
  const { user } = user_context;
  useEffect(() => {
    register_user();
  }, [user]);

  let { alert_id } = useParams()
  const { data, loading, error } = useQuery(alert_query, { variables: { alert_id }, refetchInterval: 40000 })
  const { default: dflt, current=null } = useMap();
  const map = dflt == null ? current : dflt;
  const { dispatch } = useMapContext();
  const [alert, set_alert] = useState(null)
  const [path, set_path] = useState(null)
  const [open, set_open] = useState(true)

  useEffect(() => {
    async function handle_alert(getAlert) {
      console.log(getAlert)

      if (getAlert?.vehicle){
        const alert_date = new Date(getAlert.occuredAt)
        const path = await fetch(position_between, {
          vehicle: getAlert.vehicle.id,
          start_time: new Date(alert_date.getTime() - TIME_AROUND_POS).toISOString(),
          end_time: new Date(alert_date.getTime() + TIME_AROUND_POS).toISOString(),
        })
        set_path(path.positionsForVehicle)
      }
      set_alert(getAlert)
    }

    if (error !== null) {
      console.error(error);
      return;
    }

    if (!loading) {
      const { getAlert } = data;
      handle_alert(getAlert)
    }
  }, [alert_id, data, loading, error])

  // Update map position based on alert or vehicle position
  useEffect(() => {
    if (alert == null) {
      return;
    }

    const last = get_location(alert);
    dispatch({type: "SET_VIEW_STATE", map: map, payload: {
      latitude: last.lat,
      longitude: last.lon,
      zoom: last.zoom
    }})
  }, [dispatch, alert, map]);

  if (alert == null) return <div>Loading ...</div>

  return (
    <Box display="flex">
      <GemDrawerMenu />

      <OpenButton hidden={open} onClick={() => set_open(true)} style={{ position: 'absolute', top: 15, right: 15, background: "#ffffff" }}> Details </OpenButton>
      <MoveableMap
        open={open}
        style={{ width: '100%', height: '100vh'}}
      >
        <VehiclePin vehicle={alert.vehicle} display_text={true} />
        <AlertPin alert={alert} />
        <RouteLayer route_points={alert.dig('vehicle', 'trip', 'shapePoints')} />
        <StopLayer stops={alert.dig('vehicle', 'trip', 'stops')} stop_index={alert.dig('vehicle', 'tripStopIndex')} />
        <SpeedHeadingLayer route_points={path} />
      </MoveableMap>
      <SidePanel width={FILTER_PANEL_WIDTH} open={open} set_close={() => { set_open(false) }}>
        <AlertCard alert={alert} />
        {alert.vehicle ? <VehicleCard vehicle={alert.vehicle} /> : null}
        <RouteCard trip={alert.dig('vehicle', 'trip')} route={alert.dig('vehicle', 'route')} />
      </SidePanel>
    </Box>
  )
}

export default AlertView;
