import React from "react";
import { Doughnut } from 'react-chartjs-2';
import { Box, Paper, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { styled } from "@mui/material/styles";

const FlexGrid = styled(Grid)({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
})

const TextMeta = styled(Typography)`
  && {
    font-weight: bold;
    align-self: center;
  }
`
const TextItem = styled(Typography)`
  && {
    align-self: center;
  }
`
const StatusBox = styled(Box)(({ bgcolor }) => ({
  padding: "8px",
  paddingTop: "5px",
  paddingBottom: "0px",
  borderRadius: "10px",
  color: "#ffffff",
  backgroundColor: bgcolor,
}))


function VehicleBatteryHealth({ vehicle, charge_point_connector }) {
  const {
    depotState,
    displayName,
    energyStoreSize,
    energyStoreUnits,
    totalBatteryCycles,
    currentChargingSchedule,
  } = vehicle;

  console.log("VehicleBatteryHealth", vehicle, charge_point_connector);
  let chargeState = charge_point_connector?.state;
  if (chargeState === undefined) {
    chargeState = "disconnected";
  }

  let { stateOfCharge } = vehicle

  let target = 100;
  if (currentChargingSchedule) {
    target = currentChargingSchedule.scheduledChargingEndSoc;
  }

  let soc_text = "N/A";

  if (stateOfCharge === null || stateOfCharge === undefined) {
    stateOfCharge = 100;
  } else {
    soc_text = stateOfCharge.toString() + "%";
  }

  let charge_text = "";
  let charge_box_color = "";
  if (chargeState === "disconnected") {
    charge_text = "Disconnected";
    charge_box_color = "#878787";
  } else if (chargeState === "unidentified") {
    charge_text = "Identifying";
    charge_box_color = "#B53737";
  } else if (chargeState === "charging" || chargeState === "manual_charging") {
    charge_text = "Charging";
    charge_box_color = "#028A0F";
  } else if ((chargeState === "connected" || chargeState === "manual_connected") && target !== null && stateOfCharge !== null && stateOfCharge < target) {
    charge_text = "Ready for Charging";
    // charge_box_color = "#0055b3";
    charge_box_color = "#B53737";
  } else if (chargeState === "connected" || chargeState === "manual_connected") {
    charge_text = "Finished Charging";
    charge_box_color = "#028A0F";
  } else if (chargeState === "hard_faulted") {
    charge_text = "Charge Point Faulted";
    charge_box_color = "#B53737";
  }

  let depot_text = "";
  let depot_box_color = "";
  if (depotState === "within_depot") {
    depot_text = "In Depot";
    depot_box_color = "#0055b3";
  } else if (depotState === "outside_depot") {
    depot_text = "Running";
    depot_box_color = "#028A0F";
  }

  let battery_cycles_text = "0";
  if (totalBatteryCycles) {
    battery_cycles_text = totalBatteryCycles.toString();
  }

  let battery_capacity_text = "";
  if (energyStoreUnits && energyStoreSize) {
    battery_capacity_text = energyStoreSize.toString() + " " + energyStoreUnits;
  }

  let battery_level_doughnut = "";
  if (stateOfCharge !== null) {
    let offsetTarget = Math.max(0, target - stateOfCharge);
    if (!currentChargingSchedule) {
      offsetTarget = 0;
    }

    const offsetLeft = Math.max(0, 100 - stateOfCharge - offsetTarget);
    const doughnut_data = {
      labels: ["Current", "Target", ""],
      datasets: [{
        label: "Battery Level",
        data: [stateOfCharge, offsetTarget, offsetLeft],
        backgroundColor: ["#028A0F", "#878787", "#FFFFFF"],
        hoverBackgroundColor: ["#129A1F", "#979797", "#FFFFFF"],
      }]
    };

    const doughnut_options = {
      plugins: {
        legend: {
          display: false
        }
      }
    };

    battery_level_doughnut = <Doughnut data={doughnut_data} options={doughnut_options} />;
  }

  console.log("VehicleBatteryHealth before return", vehicle, charge_point_connector);

  return (
    <Box margin="5px">
      <Paper sx={{ padding: 2, margin: 'auto' }}>
        <Grid container spacing={1}>
          <FlexGrid item>
            <TextMeta style={{ fontSize: "26px", width: "150px" }}>
              {displayName}
            </TextMeta>
            <StatusBox component="span" bgcolor={depot_box_color} style={{ width: "100px" }}>
              <TextItem>{depot_text}</TextItem>
            </StatusBox>
            <StatusBox component="span" bgcolor={charge_box_color} style={{ width: "250px" }}>
              <TextItem>{charge_text}</TextItem>
            </StatusBox>
          </FlexGrid>
        </Grid>
        <FlexGrid container spacing={1}>
          <div style={{"width": "10vw"}}>
            {battery_level_doughnut}
          </div>
          <TextItem>{soc_text}</TextItem>
        </FlexGrid>
        <FlexGrid container spacing={1}>
          <TextItem>Battery Cycles: {battery_cycles_text}</TextItem>
        </FlexGrid>
        <FlexGrid container spacing={1}>
          <TextItem>Battery Capacity: {battery_capacity_text}</TextItem>
        </FlexGrid>
      </Paper>
    </Box>
  );
}


export { VehicleBatteryHealth };
