import React, { useState, useEffect } from "react";
import { Select, MenuItem } from '@mui/material'
import { useDebounce } from "components/common";

function ExistsFilter({ exists_handler }) {
  const [selection, set_selection] = useState("NONE")

  const debounced_search = useDebounce(selection, 500);

  useEffect(() => {
    exists_handler(selection);
  }, [debounced_search]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Select
      value={selection}
      onChange={(e) => set_selection(e.target.value)}
      variant="outlined"
      size="small"
      MenuProps={{
        sx: {
          "&& .MuiList-root": {
            backgroundColor: "#ffffff"
          },
        }
      }}
    >
      <MenuItem value={"NONE"} dense > 
      </MenuItem>
      <MenuItem value={true} dense > 
        Exists
      </MenuItem>
      <MenuItem value={false} dense > 
        Does Not Exist
      </MenuItem>
    </Select>
  );
}

export { ExistsFilter };
