// Example usage:

// async function get_vehicles(is_first) {
//   let init_vehicles = await graphql_client.request(vehicles_query);
//   init_vehicles.vehicles = init_vehicles.vehicles.filter(v => v.displayName != null)
//   if (is_first) {
//     vehicle_dispatch({
//       type: "SET_VEHICLES",
//       payload: init_vehicles.vehicles,
//     });
//   } else {
//     vehicle_dispatch({
//       type: "REFRESH_VEHICLES",
//       payload: init_vehicles.vehicles,
//     });
//   }
// }


const vehicle_path_query = `
  query VehiclePathAfter(
    $vehicle: ID!,
    $after: String!
  ) {
    gpsLogsForVehicle(vehicleId: $vehicle, after: $after) {
      id
      lat
      lon
    }
  }
`;

const position_between = `
  query PositionBetween(
    $vehicle: ID!,
    $start_time: String!,
    $end_time: String
  ){
    positionsForVehicle(vehicleId: $vehicle, startTime: $start_time, endTime: $end_time){
      speed
      heading
      lat
      lon
    }
  }
`;

const vehicle_trip_query = `
query VehicleTrip($vehicle_id: ID!)
{
  vehicle(id: $vehicle_id) {
    id
    positionedAt
    displayName
    fuelLevel
    speed
    lastPosition {
      heading
      lat
      lon
    }
    trip {
      id
      stops {
          name
          code
          stopSequence
          lat
          lon
          arrivalTime
          departureTime
      }
      shapePoints {
          sequence
          lat
          lon
      }
    }
    tripStopIndex
    route {
      id
      shortName
      name
    }
  }
}
`;


const vehicles_query = `
query Vehicles {
  vehicles {
    id
    positionedAt
    routeState
    displayName
    fuelLevel
    stateOfCharge
    speed
    isElectric
    alerts {
      id
    	priority
    	displayName
    	displayType
    	alertClass
    	status
    	text
    	requiresAck
    	occuredAt
    	position {
          lat
          lon
          id
    	}
    }
    lastPosition {
      heading
      lat
      lon
    }
    trip {
      id
    }
    tripStopIndex
    route {
      id
      shortName
      name
    }
    chargingSchedules { name }
  }
}
`;
export const vehicle_query = `
query Vehicle($vehicle_id: ID!) {
  vehicle(id: $vehicle_id) {
    id
    positionedAt
    routeState
    displayName
    fuelLevel
    speed
    alerts {
      id
    	priority
    	displayName
    	displayType
    	alertClass
    	status
    	text
    	requiresAck
    	occuredAt
    	position {
      	lat
      	lon
      	id
    	}
    }
    lastPosition {
      heading
      lat
      lon
    }
    trip {
      id
      stops {
          name
          code
          stopSequence
          lat
          lon
          arrivalTime
          departureTime
      }
      shapePoints {
          sequence
          lat
          lon
      }
    }
    tripStopIndex
    route {
      id
      shortName
      name
    }
  }
}
`;

const vehicles_charging_query = `
query Vehicles {
  vehicles {
    id
    displayName
    isElectric
    depotState
    stateOfCharge
  }
}
`;

const vehicle_plot_soc_query = `
query VehicleStateOfCharges($vehicle: ID!, $from: ISO8601DateTime!, $to:ISO8601DateTime!) {
  plotStateOfCharge(id: $vehicle, from: $from, to: $to) {
    value
    observedAt
  }
}
`

const vehicle_charging_query = `
  query Vehicles(
    $vehicle: ID!
  ) {
    vehicle(id: $vehicle) {
      id
      displayName
      isElectric
      depotState
      stateOfCharge
      ocppId
      lastPosition {
        lat
        lon
      }
      depot {
        id
        name
        geofence
        timezone
      }
      energyStoreSize
      energyStoreUnits
      totalBatteryCycles
      lastBatteryCycle {
        startedAt
        finishedAt
        lowestStateOfCharge
        highestStateOfCharge
        startedStateOfCharge
        finishedStateOfCharge
      }
      chargePointConnector {
        state
      }
    }
    currentUser {
      id
      isAdmin
    }
  }
`;

const vehicle_charging_query_schedules = `
  query Vehicles(
    $vehicle: ID!
  ) {
    vehicle(id: $vehicle) {
      id
      displayName
      isElectric
      depotState
      stateOfCharge
      ocppId
      lastPosition {
        lat
        lon
      }
      depot {
        id
        name
        geofence
        timezone
      }
      energyStoreSize
      energyStoreUnits
      totalBatteryCycles
      lastBatteryCycle {
        startedAt
        finishedAt
        lowestStateOfCharge
        highestStateOfCharge
        startedStateOfCharge
        finishedStateOfCharge
      }
      chargingSchedules {
        id
        name
      }
      currentChargingSchedule {
        chargeCurrentLimit
        scheduledChargingEndSoc
        scheduledChargingStartSoc
        scheduledChargingEndTime
        scheduledChargingStartTime
        chargeLog {
          endSoc
          startSoc
          chargeEnd
          chargeStart
          chargeCompleted
        }
      }
      chargePointConnector {
        state
      }
    }
    currentUser {
      id
      isAdmin
    }
  }
`;

const create_vehicle_mutation = `
mutation CreateVehicle($input: VehicleInput!) {
    createVehicle(input: $input) {
        id
        positionedAt
        routeState
        displayName
        fuelLevel
        stateOfCharge
        speed
    }
}
`;

const update_vehicle_mutation = `
mutation UpdateVehicle($vehicle_id: ID!, $input: VehicleInput!) {
    updateVehicle(id: $vehicle_id, input: $input) {
        id
        positionedAt
        routeState
        displayName
        fuelLevel
        stateOfCharge
        speed
    }
}
`;



export { position_between, vehicle_path_query, vehicles_query, vehicles_charging_query, vehicle_plot_soc_query, vehicle_charging_query, vehicle_trip_query, create_vehicle_mutation, update_vehicle_mutation, vehicle_charging_query_schedules };
