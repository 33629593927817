import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles"
import { Paper } from "@mui/material";

import { ChargingGraphInner } from ".";
import { Loading, ChargingVehicleNode, ChargePointNode, ChargingDepotNode } from "./common";


const VehicleCard = styled(Paper)({
	position: "absolute",
	width: "250px",
	height: "20px",
	padding: "5px",
	paddingTop: "0px",
	borderRadius: "7px",
  	background: "#ffffff",
	"&:hover": {
		background: "#DDD",
	}
})

const ChargePointCard = styled(Paper)({
	position: "absolute",
	width: "75px",
	height: "20px",
	padding: "5px",
	paddingTop: "0px",
	borderRadius: "7px",
  	background: "#ffffff",
	"&:hover": {
		background: "#DDD",
	}
})

const DepotCard = styled(Paper)({
	position: "absolute",
	width: "250px",
	height: "150px",
	padding: "5px",
	paddingTop: "0px",
	borderRadius: "7px",
  	background: "#ffffff",
	"&:hover": {
		background: "#DDD",
	}
})

// Used for maintaining the data in the charging graph
let inner; // eslint-disable-line 

function ChargingGraph(props) {
	const navigate = useNavigate();
	const { depot, depot_power, margin, width, height } = props;
	const [vehicles, set_vehicles] = useState([]);
	const [charge_points, set_charge_points] = useState([]);
	const svg_ref = useRef(null);


	useEffect(() => {
		if (depot) {
			inner = new ChargingGraphInner(svg_ref.current, { data: depot, margin, width, height, onRenderVehicles: set_vehicles, onRenderChargePoints: set_charge_points });
		}
	}, [depot, margin, width, height]);

	const handle_vehicle_click = (vehicle) => {
		navigate(`/vehicle/charging/${vehicle.id}`);
	};

	if (depot == null) return <Loading />;


	return (
		<React.Fragment>
			<div ref={svg_ref} data={depot} margin={margin} />
      			<DepotCard style={{ left: 20, top: (height / 2) - 65 }}>
				<ChargingDepotNode depot={depot} depot_power={depot_power} />
			</DepotCard>
			{vehicles.map((v) => {
                const cpc = charge_points.find(e => e.connectedVehicle === v.id);
				return <VehicleCard key={v.id} style={{ left: v.x, top: (height / 2) + v.y - 5 }}>
					<ChargingVehicleNode vehicle={v} charge_point_connector={cpc} onClick={() => handle_vehicle_click(v)} />
				</VehicleCard>;
            })}
			{charge_points.map((cp) =>
				<ChargePointCard key={cp.id} style={{ "left": cp.x, "top": (height / 2) + cp.y - 5 }}>
					<ChargePointNode charge_point={cp} />
				</ChargePointCard>
			)}

		</React.Fragment>
	);
}

export { ChargingGraph };
