import React, { useState, useEffect } from 'react'
import { Select, MenuItem } from '@mui/material'

function FieldSelection({ select_handler, selections, initial_selection }) {

  const [selection, set_selection] = useState(initial_selection)

  useEffect(() => {
    select_handler(selection)
  }, [selection]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Select
      value={selection}
      onChange={(e) => set_selection(e.target.value)}
      displayEmpty
      variant="outlined"
      size="small"
      style={{ width: 170 }}
      MenuProps={{
        sx: {
          "&& .MuiList-root": {
            backgroundColor: "#ffffff"
          },
        }
      }}
    >
      {selections.map((item, idx) => (
        <MenuItem key={idx} value={item} dense > 
          {item}
        </MenuItem>
      ))}
    </Select>
  )
}

export { FieldSelection }
