import { Box } from "@mui/material";
import { useEffect } from "react";
import { Card, CardHeader } from "@mui/material";
import { useLocation, useParams } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';

import { ChargingVehicleList, Loading, useScreenDimensions } from "components/common";
import GemDrawerMenu from "components/common/DrawerMenu";

import { useDepotChargingQuery, useQuery, useUser } from "contexts";
import { depot_charging_query } from "queries";

function formatDepot(depot) {
  console.log("formatDepot", depot);
  const evs = depot.vehicles.filter(vehicle => (vehicle.isElectric && vehicle.displayName != null));

  return {
    id: depot.id,
    depot: depot,
    name: depot.name,
    geofence: depot.geofence,
    charge_points: depot.chargePoints,
    total_power: depot.totalPower,
    vehicles: evs,
    charging: evs.filter(vehicle => depot.chargePoints.map(cp => cp.chargePointConnectors.filter(cpc => cpc.connectedVehicle !== null).length).reduce((p, c) => p + c, 0)).length,
    in_service: evs.filter(vehicle => vehicle.depotState === "outside_depot").length,
    in_depot: evs.filter(vehicle => vehicle.depotState === "within_depot").length,
  }
}

function ChargePointsPage() {
  let { id } = useParams()
  console.log("Charging Points Page", id);

  const { context: user_context, register_user } = useUser();
  const { user } = user_context;
   useEffect(() => {
    register_user();
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  const { data: depot_data, error: depot_error } = 
    useDepotChargingQuery(depot_charging_query, { variables: { depot: id }, refetchInterval: 10000});

  // const {data: depot_data, error: depot_error} = useQuery(depot_charging_query, { variables: { depot: id }, refetchInterval: 10000 });
  // const {depot_data} = useQuery(depot_charging_query, { variables: { depot: id }, refetchInterval: 10000 });

  if (depot_error != null ) {
    return (
      <Box>
        <Box>{depot_error}</Box>
      </Box>
    )
  };

  if (depot_data == null ) return <Loading />

  let depot = null;
  if (!depot_data) {
    depot = null;
  }else{
    depot = formatDepot(depot_data.depot);
  }

  depot = depot_data.depot;

  console.log("ChargePointsPage: ", depot);
  return (
    <Box display="flex" width="100%">
      <GemDrawerMenu />
      <Box sx={{ flexGrow: 1, padding: '5px' }}>
        <Card>
            <CardHeader title="Charge Points" />
            <Box sx={{ height: '100%', width: '100%' }}>
            <DataGrid
              columns={[
                { field: 'id', headerName: 'ID', width: 150, type: 'number' },
                { field: 'name', headerName: 'Charge Point Name', width: 200 },
                { field: 'connector1', headerName: 'Connector 1', width: 200 },
                { field: 'vehicle1', headerName: 'Vehicle 1', width: 200 },
                { field: 'connector2', headerName: 'Connector 2', width: 200 },
                { field: 'vehicle2', headerName: 'Vehicle 2', width: 200 },
              ]}
              rows={depot.chargePoints.map(cp => ({
                id: cp.id,
                name: cp.name,
                connector1: cp.chargePointConnectors[0] ? cp.chargePointConnectors[0].state : 'None',
                vehicle1: cp.chargePointConnectors[0] && cp.chargePointConnectors[0].connectedVehicle ? cp.chargePointConnectors[0].connectedVehicle : 'None',
                connector2: cp.chargePointConnectors[1] ? cp.chargePointConnectors[1].state : 'None',
                vehicle2: cp.chargePointConnectors[1] && cp.chargePointConnectors[1].connectedVehicle ? cp.chargePointConnectors[1].connectedVehicle : 'None',
              }))}
              pageSize={5}
              rowsPerPageOptions={[5]}
              sortModel={[
                {
                  field: 'id',
                  sort: 'asc',
                },
              ]}
            />
            </Box>
        </Card>
      </Box>
    </Box>
  );
}

export { ChargePointsPage };

