import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import LogoBall from "assets/orion-logo-ball.png";
import LogoText from "assets/orion-always-connected.png";
import MastercomLogo from "assets/Mastercom.png";
import { useLogoQuery } from "contexts";
import { default_logo_query } from "queries";
import { Loading } from ".";


// It looks like this attempts to get the logo from the database, else it uses the default logo.

function OptionsPanelLogo() {
  const [logo, set_logo] = useState(null);

  if (logo == null) {
    return ( 
      // padding order. top right bottom left
      <Box sx={{ display: "flex", alignItems: "center", justifyItems: "center", boxSizing: "unset"}}>
        <img
          src={MastercomLogo}
          height="45px"
          style={{ padding: '3px 0px 0px 3px'}}
          alt="Logo"
        />
        {/* <img
          src={LogoText}
          height="37px"
          style={{ padding: '3px 0px 0px 3px'}}
          alt="Logo"
        /> */}
      </Box>
    );
  } else {
    return ( 
      <Box>
        <img
          src={logo}
          height="45px"
          style={{ padding: '15px 5px 10px 10px', alignSelf: 'center', justifyContent: 'center', width: "90%"}}
          alt="Logo"
        />
      </Box>
    );
  }
}

export { OptionsPanelLogo }
