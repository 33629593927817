import { createTheme } from "@mui/material/styles"

const baseTheme = createTheme({
  typography: {
    h6: {
      fontWeight: 500,
      fontSize: 18,
      letterSpacing: 1.5,
    },
    fontSize: 16,
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(",")
  }
});

const theme = createTheme({
  ...baseTheme,
  status: {
    connected: "#4CAF50",
    disconnected: "#D50000",
    Info: "#1C89BF",
    Expired: "#000000",
    Waiting: "#D50000",
    Critical: "#D50000",
    High: "#D50000",
    "In Progress": "#4CAF50",
    Medium: "#FBC400",
    Done: "#4CAF50",
    Low: "#1C89BF",
  },
  alerts: {
    criticalDark: "#FF0000",
    criticalLight: "#EDC9C9",
    criticalAlpha: "#FF000000",
    highDark: "#FF0000",
    highLight: "#EDC9C9",
    highAlpha: "#FF000000",
    infoDark: "#1B9FFF",
    infoLight: "#D1E8E2",
    infoAlpha: "#1B9FFF00",
    warningDark: "#FBC400",
    warningLight: "#EDEDC9",
    warningAlpha: "#FBC40000",
    expiredDark: "#000000",
    expiredLight: "#BDBDBD",
    expiredAlpha: "#00000000",
    successDark: "#00FF00",
    successAlpha: "#00FF0000",
  },
  palette: {
    // background: '#1E344B',
    background: {
      default: '#1E344B',   // not used?
      paper: '#FFFFFF',     // This needs to be white, or all kinda of things go dark blue. BMH
      // paper: '#1E344B',
      default: '#FFFFFF',       // top bar. White?
    },
    // background_light: '#a9c3de',
    on_background: '#99b1c9',
    on_background_light: '#FFFFFF',
    // primary: {
    //   main: '#1E344B',
    //   default: '#1E344B',
    // }
  },
  routes: {
    matched: "#1B9FFF",
    unknown: "#E7582A",
    not_on_route: "#E7582A",
  },
  transitions: {
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      // most basic recommended timing
      standard: 300,
      // this is to be used in complex animations
      complex: 375,
      // recommended when something is entering screen
      enteringScreen: 225,
      // recommended when something is leaving screen
      leavingScreen: 195,
    },
    easing: {
      // This is the most common easing curve.
      easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
      // Objects enter the screen at full velocity from off-screen and
      // slowly decelerate to a resting point.
      easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
      // Objects leave the screen at full velocity. They do not decelerate when off-screen.
      easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
      // The sharp curve is used by objects that may return to the screen at any time.
      sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
    },
  },
  overrides: {
    MuiPaper: {
      root: {
        backgroundColor: "#ffffff"
      }
    }
  }
});

export { theme };
