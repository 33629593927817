import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Box, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, Paper, TableHead } from "@mui/material";
import Typography from "@mui/material/Typography";
import { GatewayContext } from "contexts";

function GatewayTable(props) {
  const navigate = useNavigate();
  let { gateways, dispatch } = useContext(GatewayContext);
  const [page, set_page] = React.useState(0);
  const [rows_per_page, set_rows_per_page] = useState(25);

  const handle_change_page = (event, new_page) => set_page(new_page);

  const handle_change_rows_per_page = (event) => set_rows_per_page(+event.target.value);

  const handle_row_click = (row) => {
    dispatch({ type: "SELECT_GATEWAY", payload: row });
    navigate(`/gateway/${row.id}`);
  };

  const handle_navigate = (uri) => {
    navigate(uri);
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden", backgroundColor: "#ffffff" }}>
      <TableContainer sx={{ maxHeight: "calc(100% - 50px)"}}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: 0.15, padding: "1px", backgroundColor: "#ffffff" }} />
              <GatewayMeta meta={gateways.header} />
            </TableRow>
          </TableHead>
          <TableBody>
            <GatewayList
              handle_click={handle_row_click}
              handle_navigate={handle_navigate}
              map={gateways.meta_map}
              gateways={gateways.filtered.slice(page * rows_per_page, page * rows_per_page + rows_per_page)}
            />
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        style={{ overflow: 'visible', height: "50px"}}
        count={gateways.filtered.length}
        rowsPerPage={rows_per_page}
        page={page}
        onPageChange={handle_change_page}
        onRowsPerPageChange={handle_change_rows_per_page}
      />
    </Paper>
  );
}

function GatewayList({ gateways, handle_click, map, handle_navigate }) {
  return gateways.map((row) => <PriorityRow handle_navigate={handle_navigate} on_click={(e) => { if (e.target.tagName === "TD") { handle_click(row); }}} map={map} key={row.id} row={row} />);
}

function GatewayMeta({ meta }) {

  if ( meta == null ) return <TableCell>"No Content"</TableCell>

  return (
    meta.map((meta, idx) => (
      <TableCell key={idx} style={{ ...meta.style, backgroundColor: "#ffffff" }}>
        <Typography variant="h6">{meta.display_name}</Typography>
      </TableCell>
    ))
  )
}

function GatewayCell({ row, meta, idx, handle_navigate }){
  const { id, type, props } = meta 
  const value = row[id];

  switch (type) {
    case "Link":
      const link_text = props === undefined || props.link_text === undefined ? id : props.link_text;
      const link_href = props === undefined || props.link_href === undefined ? value : props.link_href;
      const link_internal_type = props === undefined || props.link_internal_type === undefined ? null : props.link_internal_type;

      const on_click = link_internal_type === null ? () => {} : () => {
        handle_navigate(`/${link_internal_type}/${value}`);
      };

      return <Button variant="contained" disabled={link_href === null} href={link_href} onClick={on_click}>{link_text}</Button>;
    default:
      return value;
  }
}

function PriorityRow ({ row, map, on_click, handle_navigate }){
  let meta_vals = Object.values(map);

  return (
    <TableRow
      hover
      role="checkbox"
      tabIndex={-1}
      key={row.id}
      onClick={on_click}
    >

      <TableCell sx={{ padding: "2px", borderRadius: "8px 0px 0px 8px", backgroundColor: 'white' }} />
      {meta_vals.map((k, i) => 
        <TableCell style={k.style} key={k.id} > 
          <GatewayCell handle_navigate={handle_navigate} row={row} meta={k} idx={i} />
        </TableCell>
      )}
    </TableRow>
  );
};

export { GatewayTable, GatewayList };
