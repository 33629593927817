const user_reducer = (state, action, data) => {
  console.log("user_reducer", state, action, data);
  switch (action.type) {
    case "SET_AUTH0_USER":
      const auth0_user = action.payload;
      return {
        ...state,
        name: auth0_user.name,
        given_name: auth0_user.given_name,
        family_name: auth0_user.family_name,
        middle_name: auth0_user.middle_name,
        nickname: auth0_user.nickname,
        preferred_auth0_username: auth0_user.preferred_auth0_username,
        profile: auth0_user.profile,
        picture: auth0_user.picture,
        website: auth0_user.website,
        email: auth0_user.email,
        email_verified: auth0_user.email_verified,
        gender: auth0_user.gender,
        birthdate: auth0_user.birthdate,
        zoneinfo: auth0_user.zoneinfo,
        locale: auth0_user.locale,
        phone_number: auth0_user.phone_number,
        phone_number_verified: auth0_user.phone_number_verified,
        address: auth0_user.address,
        updated_at: auth0_user.updated_at,
        identifier: auth0_user.sub,
      };
    default:
      return state;
  }
};

export { user_reducer };
