import { Box } from "@mui/material";
import { useEffect } from "react";
import { Card, CardHeader } from "@mui/material";
import { useLocation, useParams } from 'react-router-dom';

import { ChargingVehicleList, Loading, useScreenDimensions } from "components/common";
import GemDrawerMenu from "components/common/DrawerMenu";

import { useDepotChargingQuery, useQuery, useUser } from "contexts";
import { depot_charging_query } from "queries";

function formatDepot(depot) {
  console.log("formatDepot", depot);
  const evs = depot.vehicles.filter(vehicle => (vehicle.isElectric && vehicle.displayName != null));

  return {
    depot: depot,
    name: depot.name,
    geofence: depot.geofence,
    charge_points: depot.chargePoints,
    total_power: depot.totalPower,
    vehicles: evs,
    charging: evs.filter(vehicle => depot.chargePoints.map(cp => cp.chargePointConnectors.filter(cpc => cpc.connectedVehicle !== null).length).reduce((p, c) => p + c, 0)).length,
    in_service: evs.filter(vehicle => vehicle.depotState === "outside_depot").length,
    in_depot: evs.filter(vehicle => vehicle.depotState === "within_depot").length,
  }
}

function ChargingPage() {
  let { id } = useParams()
  console.log("ChargingPage", id);

  const { context: user_context, register_user } = useUser();
  const { user } = user_context;
   useEffect(() => {
    register_user();
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  const { data: depot_data, error: depot_error } = 
    useDepotChargingQuery(depot_charging_query, { variables: { depot: id }, refetchInterval: 10000});

  // const {data: depot_data, error: depot_error} = useQuery(depot_charging_query, { variables: { depot: id }, refetchInterval: 10000 });
  // const {depot_data} = useQuery(depot_charging_query, { variables: { depot: id }, refetchInterval: 10000 });

  if (depot_error != null ) {
    return (
      <Box>
        <Box>{depot_error}</Box>
      </Box>
    )
  };

  // if (depot_data == null ) return <Loading />

  let depot = null;
  if (!depot_data) {
    depot = null;
  }else{
    depot = formatDepot(depot_data.depot);
  }

  return (
    <Box display="flex" width="100%">
      <GemDrawerMenu />
      <Box sx={{ flexGrow: 1, padding: '5px' }}>
        <Card>
          <Box sx={{ backgroundColor: '#07A0C3', width: '100%', padding: '0px' }}>
            <CardHeader title="Electric Vehicles" />
          </Box>
          {depot ? (
            <ChargingVehicleList vehicles={depot.vehicles} charge_points={depot.charge_points} />
          ) : (
            <Loading />
          )}
        </Card>
      </Box>
    </Box>
  );
}

export { ChargingPage };

