import React from "react";
import { useMap, Marker } from "react-map-gl";
import { HeadingArrowSVG } from "components/common";
import { useTheme } from "@mui/material/styles";

function VehiclePin(props) {
  const {
    vehicle,
    onMouseEnter = () => {},
    onClick = () => {},
    onMouseLeave = () => {},
    display_text,
  } = props;

  const { routes, alerts: alerts_theme } = useTheme();

  if (vehicle == null) return null;
  const { lat, lon, heading } = vehicle.lastPosition ? vehicle.lastPosition : { lat: null, lon: null, heading: null };
  if (lat == null || lon == null) return null;
  const { route, displayName, alerts } = vehicle;
  // If we want to use 3 characters for QLD, and 4 for NSW, we should perhaps use the display_name_4c field. If we want 4 for both, we can use the slice as below.
  const shortName = displayName ? displayName.slice(-4) : '';
  const outer_radius = 26;
  const middle_radius = 18;
  const inner_radius = 16;
  const px_size = 12;
  let gradient_id = 'Default'
  let circle_colour = "rgb(0, 181, 239)"
  let alert_priority = alerts ? alerts.map(a => a.priority) : [];

  if (alert_priority.includes("Critical")){
    gradient_id = 'Critical' 
  } else if (alert_priority.includes("High")){
    gradient_id = 'High'
  } else if (alert_priority.includes("Medium")){
    gradient_id = 'Medium'
  } else if (alert_priority.includes("Low")){
    gradient_id = 'Low'
  }


  if (route){
      circle_colour = routes.matched;
  } else {
      circle_colour = routes.not_on_route;
  }

  return (
    <Marker
      style={{ display: "block", margin: "auto" }}
      latitude={lat}
      longitude={lon}
    >
      <div 
        onClick={(e) => { console.log(e); onClick(vehicle)}}
        onMouseEnter={() => onMouseEnter(vehicle)}
        onMouseLeave={() => onMouseLeave(vehicle)}
      >
      <svg
        height={outer_radius * 2}
        width={outer_radius * 2}
        xmlns="http://www.w3.org/2000/svg"
        style={{
          cursor: "pointer",
        }}
           >
        <defs>
          <radialGradient id='Critical'>
            <stop offset="70%" stopColor={alerts_theme.criticalDark}/>
            <stop offset="85%" stopColor={alerts_theme.criticalAlpha} />
          </radialGradient>
          <radialGradient id='High'>
            <stop offset="70%" stopColor={alerts_theme.highDark} />
            <stop offset="85%" stopColor={alerts_theme.highAlpha} />
          </radialGradient>
          <radialGradient id='Medium'>
            <stop offset="70%" stopColor={alerts_theme.warningDark} />
            <stop offset="85%" stopColor={alerts_theme.warningAlpha} />
          </radialGradient>
          <radialGradient id='Low'>
            <stop offset="70%" stopColor={alerts_theme.infoDark} />
            <stop offset="85%" stopColor={alerts_theme.infoAlpha} />
          </radialGradient>
          <radialGradient id='Default'>
            <stop offset="50%" stopColor="#FFFFFF" />
            <stop offset="75%" stopColor="#FFFFFF00" />
          </radialGradient>;

        </defs>
        <circle
          cx={outer_radius}
          cy={outer_radius}
          r={outer_radius}
          fill={`url(#${gradient_id})`}

        />
        <circle
          cx={outer_radius}
          cy={outer_radius}
          r={middle_radius}
          fill="#ffffff"
        />
        {heading !== 0 && heading != null ?
          <HeadingArrowSVG 
            base_length={middle_radius} 
            translate_x={outer_radius}
            translate_y={outer_radius}
            heading_offset={-90}
            heading={heading}
            fill="black"
          />
          :
          null
        }
        <circle
          cx={outer_radius}
          cy={outer_radius}
          r={inner_radius}
          fill={circle_colour}

        />
        {display_text ? (
          <text
            style={{ font: `${px_size}px roboto`, whiteSpace: "pre" }}
            x="50%"
            y={outer_radius + px_size / 3 + 1}
            textAnchor="middle"
            stroke="#ffffff"
            fill="#ffffff"
          >
            {shortName}
          </text>
        ) : null}
      </svg>
      </div>
    </Marker>
  );
}

function VehiclePins(props) {
  const {
    vehicles,
    viewport,
    text_start_depth,
    force_text,
    ...childProps
  } = props;

  const { current: map } = useMap();

  let display_text = false;
  
  if (force_text) {
    display_text = true;
  } else if (text_start_depth) {
    display_text = map.getZoom() > text_start_depth;
  }

  const vehicle_list = vehicles.map((vehicle) => (
    <VehiclePin
      {...childProps}
      key={vehicle.id}
      vehicle={vehicle}
      display_text={display_text}
    />
  ));

  return <React.Fragment>{vehicle_list}</React.Fragment>;
}

export { VehiclePin, VehiclePins };
