import { gql } from "graphql-request";

const get_org_schedules = gql`{
#    orgChargingSchedules(orderBy: {depot:asc, startDate:asc, endDate:asc, monday:asc, tuesday:asc, wednesday:asc, thursday:asc, friday:asc, saturday:asc, sunday:asc}) {
    orgChargingSchedules {
      id
      name
      depot { id name }
      startDate
      endDate
      # vehicles { id }
      chargeCurrentLimit
      scheduledChargingEndSoc
      scheduledChargingStartSoc
      scheduledChargingEndTime
      scheduledChargingStartTime
      completionCondition
      monday
      tuesday
      wednesday
      thursday
      friday
      saturday
      sunday
    }
  }`;

  const get_depot_schedules = `
      query getDepotSchedules($depotId: ID!) {
        depotChargingSchedules (depotId: $depotId) {
          id
          name
          # depot { id name }
          startDate
          endDate
          # vehicles { id }
          chargeCurrentLimit
          scheduledChargingEndSoc
          scheduledChargingStartSoc
          scheduledChargingEndTime
          scheduledChargingStartTime
          completionCondition
          monday
          tuesday
          wednesday
          thursday
          friday
          saturday
          sunday
          enabled
          vehicleCount
        }
        currentUser {
            id
            isAdmin
        }
      }`;

    //   const get_current_user = gql`
    //   query getCurrentUser {
    //     currentUser {
    //       id
    //       name
    //       email
    //       role
    //     }
    //   }
    // `;      

      const get_vehicle_possible_schedules = `
      query getDepotSchedules($vehicleId: ID!) {
        vehiclePossibleChargingSchedules (vehicleId: $vehicleId) {
          id
          name
          # depot { id name }
          startDate
          endDate
          # vehicles { id }
          chargeCurrentLimit
          scheduledChargingEndSoc
          scheduledChargingStartSoc
          scheduledChargingEndTime
          scheduledChargingStartTime
          completionCondition
          monday
          tuesday
          wednesday
          thursday
          friday
          saturday
          sunday
        }
      }`;


const edit_schedule = gql`
  mutation updateChargingSchedule($input: ChargingScheduleInput!, $id: ID!) {
    updateChargingSchedule(
      input: $input
      id: $id
    ) {
      id
      name
      chargeCurrentLimit
      startDate
      endDate
      scheduledChargingEndSoc
      scheduledChargingStartTime
      scheduledChargingEndTime
      completionCondition
      monday
      tuesday
      wednesday
      thursday
      friday
      saturday
      sunday
      enabled
    }
  }`;

  // We should pass schedule IDs, but I've had some problems with the datagrid dropdown.
  // Going to use name temporarily.
  const link_schedule_vehicle_gql = gql`
  mutation linkScheduleToVehicle($vehicle: ID!, $schedules: [ID!]!) {
    linkScheduleToVehicle(
      vehicle: $vehicle
      schedules: $schedules
    ) {
      id
      displayName
      chargingSchedules { id name }
    }
  }`;

//   const link_schedule_vehicle = gql`
//   mutation linkScheduleNamesToVehicle($vehicle: ID!, $schedules: [String]) {
//     linkScheduleNamesToVehicle(
//       vehicle: $vehicle
//       schedules: $schedules
//     ) {
//       id
//       displayName
//       chargingSchedules { id name }
//     }
//   }
// `;


export { get_org_schedules, edit_schedule, link_schedule_vehicle_gql, get_depot_schedules, get_vehicle_possible_schedules };