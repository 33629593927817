import React from "react";
import "./index.css";
import ContextProvider from "./ContextProviders"
import * as serviceWorker from "./serviceWorker";
// import history from "./utils/history";
import { Auth0Provider } from '@auth0/auth0-react';

import path_config from "./path_config.json";
import auth_config from "./auth_config.json";
import { createRoot } from "react-dom/client";

const graphql_url = (process.env.REACT_APP_GEMINI_URL || "https://gemini.orionair.com.au/graphql").trim();

createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <Auth0Provider
      basePath={path_config.base_path}
      useRefreshTokens={true}
      cacheLocation={"localstorage"}
      domain={auth_config.domain}
      audience={auth_config.audience}
      clientId={auth_config.clientId}
      redirectUri={window.location.origin + path_config.base_path}
    >
      <ContextProvider base_path={path_config.base_path} graphql_url={graphql_url} />
    </Auth0Provider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
